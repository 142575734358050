import {
  IconChartBar,
  IconFile,
  IconLogout,
  IconMenu,
  IconPackage,
  IconSettings,
  IconLink as Url,
} from '@tabler/icons-react';
import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';
import packageJson from 'package.json';
import React, { useMemo, useState } from 'react';

import { Button, Card, Link, toast } from '@polyphonic/ui';

import { ThemeToggle } from '@/components/common/theme-toggle';
import { Logo } from '@/components/svg/logo';
import { useBreakpoint } from '@/hooks/use-breakpoint';

// import { useFeatureFlag } from '@/hooks/use-feature-flag';

type NavigationProps = {
  title: string;
};

const Navigation = ({ title }: NavigationProps) => {
  const breakpoint = useBreakpoint('lg');
  const [drawerStatus, toggleDraw] = useState('translateX(-110%)');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toggle = () => {
    if (drawerStatus === 'translateX(-110%)') {
      toggleDraw('translateX(0)');
    } else {
      toggleDraw('translateX(-110%)');
    }
  };
  const router = useRouter();

  const logout = async () => {
    setIsSubmitting(true);
    await Auth.signOut({ global: true })
      .then(() => {
        router.push('/');
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  // const { enabled: distribution, isLoading: distributionLoading } =
  //   useFeatureFlag('distribution');
  // const { enabled: agreements, isLoading: agreementsLoading } =
  //   useFeatureFlag('agreements');

  const nav = useMemo(() => {
    const menuLinks = [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: <IconChartBar size={18} style={{ marginBottom: '4px' }} />,
        order: 1,
      },
      {
        title: 'Agreements',
        href: '/agreements',
        icon: <IconFile size={18} style={{ marginBottom: '4px' }} />,
        order: 2,
      },
      {
        title: 'Distribution 🛠️',
        href: '/distribution',
        icon: <IconPackage size={18} style={{ marginBottom: '4px' }} />,
        order: 3,
      },
      {
        title: 'Links',
        href: '/links',
        icon: <Url size={18} style={{ marginBottom: '4px' }} />,
        order: 4,
      },
      // ...(distribution
      //   ? [
      //       {
      //         title: 'Distribution',
      //         href: '/distribution',
      //         icon: <IconPackage size={18} style={{ marginBottom: '4px' }} />,
      //         order: 3,
      //       },
      //     ]
      //   : []),
      // ...(agreements
      //   ? [
      //       {
      //         title: 'Agreements',
      //         href: '/agreements',
      //         icon: <IconFile size={18} style={{ marginBottom: '4px' }} />,
      //         order: 4,
      //       },
      //     ]
      //   : []),
      {
        title: 'Settings',
        href: '/settings',
        icon: <IconSettings size={18} style={{ marginBottom: '4px' }} />,
        order: 5,
      },
    ];
    return menuLinks;
  }, []);

  return (
    <>
      <Card
        className="grid-in-aside flex flex-col items-center justify-start bg-neu w-[calc(100%-20px)] lg:w-[240px] lg:h-[calc(100%-20px)] mt-2.5 mr-0 mb-2.5 ml-2.5 top-[60px] lg:top-auto overflow-y-auto shadow-none filter-none z-20 absolute lg:fixed pt-4 lg:pt-0"
        style={{
          transform: breakpoint.isAboveLg ? 'translateX(0)' : drawerStatus,
        }}
        as="aside"
      >
        <Link className="m-10 hidden lg:block text-foreground" href="/">
          <Logo width="35px" height="35px" />
        </Link>
        <nav className="flex flex-col px-6 w-full">
          {nav.map((link) => (
            <Link
              key={link.title}
              href={link.href}
              className="flex flex-row items-center text-foreground mb-1"
              style={{
                color:
                  router.route === link.href
                    ? 'hsl(var(--theme-primary))'
                    : 'hsl(var(--theme-foreground))',
                cursor: router.route === link.href ? 'default' : 'pointer',
                pointerEvents: router.route === link.href ? 'none' : 'auto',
              }}
            >
              <div className="text-foreground mr-2.5">{link.icon}</div>
              <p className="px-0 py-1.5 font-display font-bold text-inherit">
                {link.title}
              </p>
            </Link>
          ))}
          <Link
            className="items-center flex no-underline text-foreground hover:text-primary sm:hidden"
            onClick={logout}
            data-cy="signout-button"
          >
            <IconLogout size={18} />{' '}
            <p className="px-2 pt-2 font-display font-bold">Logout</p>
          </Link>
        </nav>
        <div className="flex jutsify-center mt-auto pb-2">
          <p className="text-xs">v{packageJson.version}</p>
        </div>
      </Card>
      <Card
        className="grid-in-header flex px-1.5 flex-row justify-center shadow-none w-auto filter-none rounded-none lg:rounded-full bg-neu m-0 lg:ml-5 lg:mr-2.5 lg:my-2.5"
        as="header"
      >
        <div className="flex mr-auto flex-start items-center flex-1">
          <Button
            onClick={toggle}
            isIconOnly
            className="flex lg:hidden rounded-full"
          >
            <IconMenu style={{ minWidth: '24px', minHeight: '24px' }} />
          </Button>
        </div>
        <div className="flex justify-center flex-1 items-center">
          <h5 className="font-semibold m-0">{title}</h5>
          {/* TODO: decide on breadcrumbs {title === 'Dashboard' ? null : (
            <>
              <Heading as="h4" css={{ padding: '16px 0' }}>
                <Play size={14} />
              </Heading>
              <Link href={'/dashboard/' + titleLink}>
                <NavLink>
                  <Heading as="h4">{title}</Heading>
                </NavLink>
              </Link>
            </>
          )} */}
        </div>
        <div className="flex p-0 items-center ml-auto justify-end flex-1">
          <ThemeToggle />
          <Button
            color="primary"
            className="hidden mb-2 mt-2 ml-2.5 sm:block rounded-full font-semibold"
            aria-label="Logout"
            type="submit"
            onClick={logout}
            data-cy="signout-button"
            isLoading={isSubmitting}
          >
            Sign Out
          </Button>
        </div>
      </Card>
    </>
  );
};

export default Navigation;
